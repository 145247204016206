<template>
  <div class="relative">
    <div v-if="wear === -1" class="wear-bar-container flex">
      <div class="progress-bar" style="width: 100%; background-color: #d8d8d8" />
    </div>
    <template v-else>
      <div class="wear-bar-container flex">
        <div class="progress-bar" style="width: 7%; background-color: #008000" />
        <div class="progress-bar" style="width: 8%; background-color: #5cb85c" />
        <div class="progress-bar" style="width: 23%; background-color: #f0ad4e" />
        <div class="progress-bar" style="width: 7%; background-color: #d9534f" />
        <div class="progress-bar" style="width: 55%; background-color: #993a38" />
      </div>
      <div class="wear-position" :style="{ left: `${props.wear * 100}%`, marginLeft: '-6px' }" />
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  wear: {
    type: Number,
    default: 0
  }
})
</script>

<style lang="scss" scoped>
.wear-bar-container {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 6px;
}
.wear-position {
  padding-top: 6px;
  position: absolute;
  top: -6px;
  // width: 1px;
  // height: 100%;
  // background-color: #fff;
  width: 0;
  height: 0;
  border-left: 6px solid transparent; /* 底边的一半 */
  border-right: 6px solid transparent; /* 底边的一半 */
  border-bottom: 6px solid #f0ad4e; /* 正三角形的高度为底边的一半乘以根号三 */
  transform: rotate(180deg);

  // border-bottom: 100px solid transparent;
}
</style>
